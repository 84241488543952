import { Grid, Box, Typography, Theme } from '@mui/material'
import { Strapi__Component_Links_Button, Strapi__Component_Lite_Start_Step } from 'gatsby-graphql'
import React from 'react'
import { StepLabel } from '@/components/elements/step-label'
import { useIsDesktopLarge, useIsMobile } from '@/utils/hooks'
import CustomLink from '@/components/elements/custom-link'

interface IGettingStartedProps {
  theme: Theme
  title: string
  steps: Array<Strapi__Component_Lite_Start_Step>
  freeTrialButton: Strapi__Component_Links_Button
}
const GettingStarted = (props: IGettingStartedProps) => {
  const { theme } = props
  const isMobile = useIsMobile()
  const isDesktopLarge = useIsDesktopLarge()

  // this is a workaround for difference in style generated by gatsby on build and on develop.
  //generally speaking the issue was that in build every child of container had maxWidth: 50%
  // In some tests those styles hardcoded here were not even applied, but they somehow made gatsby to load the proper styles
  // (ones that were visable in gatsby develop and not in build)

  const workaroundWidth = isMobile ? '100%' : isDesktopLarge ? '1140px !important' : '900px !important'
  return (
    <Box
      sx={{
        //workaround
        width: workaroundWidth,
        maxWidth: workaroundWidth,
        minWidth: workaroundWidth,
      }}
    >
      <Grid
        container
        sx={{
          //workaround
          width: workaroundWidth,
          maxWidth: workaroundWidth,
          minWidth: workaroundWidth,
          //
          marginTop: '20px',
          marginBottom: '64px',
        }}
      >
        <Grid item xs={12} sx={{ marginBottom: '36px' }}>
          <Typography sx={{ fontSize: '30px', fontWeight: 'bold', textAlign: 'center' }}>{props?.title}</Typography>
        </Grid>
        {props?.steps?.map((step, index) => (
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: 'flex',
              paddingBottom: { xs: '50px', md: 'inherit' },
              borderLeft: { xs: 'inherit', md: index == 0 ? 'inherit' : `2px solid ${theme.palette.text.disabled}` },
            }}
          >
            <Grid
              container
              sx={{
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
              }}
            >
              <Box sx={{ width: '100%', paddingBottom: '20px' }}>
                <StepLabel style={{ margin: 'auto' }}>
                  <Box
                    sx={{
                      height: '100%',
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography sx={{ fontSize: 40, fontWeight: 'bold' }}>
                      {step?.Number?.toString().padStart(2, '0')}
                    </Typography>
                  </Box>
                </StepLabel>
              </Box>
              <Box sx={{ width: '100%', paddingTop: { xs: '20px', md: 'inherit' } }}>
                <Typography sx={{ marginX: 'auto', fontSize: '20px', width: '75%', textAlign: 'center' }}>
                  {step?.PreLink}
                  <CustomLink link={step?.Link} sx={{ width: '100%', textDecoration: 'none', color: 'text.primary' }}>
                    <strong> {step?.Link?.text} </strong>
                  </CustomLink>
                  {step?.PostLink}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default GettingStarted
